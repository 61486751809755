<template>
    <div class="page">
        <van-nav-bar title="个人资料" left-text="返回" left-arrow @click-left="back"></van-nav-bar>
        <van-cell title="类型" :value="userInfo.type" ></van-cell>
        <van-cell title="微信昵称" :value="userInfo.nickname" ></van-cell>
        <van-cell class="mt-10">
            <template #title>
                <div>
                    <span>真实姓名</span>
                    <span :style="userInfo.username==null?'float:right;color:red;':'float:right;'">{{userInfo.username==null?'未绑定':userInfo.username}}</span>
                </div>
            </template>
        </van-cell>
        <van-cell >
            <template #title>
                <div>
                    <span>手机号</span>
                    <span :style="userInfo.mobile==null?'float:right;color:red;':'float:right;'">{{userInfo.mobile==null?'未绑定':userInfo.mobile}}</span>
                </div>
            </template>
        </van-cell>
        
        
        <div class="form_btn" style="margin: 16px;">
            <van-button round block type="info" @click="gotoEdit()"> {{userInfo.username == null && userInfo.mobile == null? '绑定个人信息' : '编辑个人信息'}} </van-button>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                userInfo: null,
                
            };
        },
        mounted(){
            this.userInfo = {...this.$user};
        },
        methods: {
            gotoEdit() {
                this.$router.push("/my/edit");
            },
            back() {
                this.$back();
            },
        }
    };
</script>

<style lang="less" scoped>
    .logout {
        bottom: 0;
        height: 50px;
        color: #ff5f16;
    }

    /* 本页公共样式 */
    .gray {
        color: #797d82;
    }

    .bott0 {
        bottom: 0;
    }

    .orange {
        color: #ff5f16
    }

    .f15 {
        font-size: 15px;
    }

    .bd-gray {
        border-bottom: 1px solid #f5f5f5;
    }

    // 设置
    header {
        .city {
            height: 44px;
            line-height: 44px;
        }
    }

    // 账号ID
    .id {
        height: 49px;
        line-height: 49px;

    }
</style>
<style lang="less">
    .van-nav-bar .van-icon , .van-nav-bar__text {
        color: #20a0ff;
    }
</style>

